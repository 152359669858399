<template>
  <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
    <div class="uk-margin">
      <div uk-grid class="uk-grid-small">
        <div class="uk-form-controls uk-width-small">
          <select class="uk-select" id="form-horizontal-select" v-model="filterType">
            <option :value=null>Select Filter</option>
            <option :value='"company_office_id"'>Property</option>
            <option :value='"role_id"'>Role</option>
          </select>
        </div>
        <div class="uk-inline uk-width-1-4">
          <multiselect
            v-model="meta[filterType]"
            :placeholder="!filterType ? 'Select filter to search': 'Type to search'"
            label="name"
            name="mitra"
            track-by="name"
            :disabled="!filterType"
            :options="filterType === 'role_id' ? role_options : office_options"
            :searchable="true"
            :loading='isFetching'
            :internal-search="false"
            :options-limit="50"
            @search-change="val => onQueryChange(val)"
          >
          </multiselect>
        </div>
        <div class="uk-inline">
          <button style="width:100px; cursor:pointer; height:40px" class="uk-button-primary" @click="showModal('propertyRoleFormModal')">Create</button>
        </div>
      </div>
    </div>

    <div class="uk-card uk-card-default uk-margin">
      <div class="uk-overflow-auto">
        <table class="uk-table uk-table-small uk-table-divider uk-table-striped">
          <thead>
            <tr>
              <th class="uk-text-center">Property</th>
              <th class="uk-text-center">Role</th>
              <th class="uk-text-center">Normal Workhour (Hours)</th>
              <th class="uk-text-center">Break Time (Hours)</th>
              <th class="uk-text-center">Fee Normal (Rp)</th>
              <th class="uk-text-center">Fee Overtime Hourly (Rp)</th>
              <th class="uk-text-center uk-width-1-6">Action</th>
            </tr>
          </thead>
          <tbody v-if="property_role_settings.docs.length>0" class="uk-table-middle uk-text-center ">
            <tr v-for="(property_role, i) in property_role_settings.docs" :key="i">
              <td>{{property_role.company_office.name}}</td>
              <td>{{property_role.role.name}}</td>
               <td>{{property_role.normal_work_hour}}</td>
              <td>{{property_role.break_time}}</td>
              <td>{{`${property_role.fee_normal} / ${property_role.fee_normal_unit}`}}</td>
              <td>{{property_role.fee_overtime_hourly}}</td>
              <td>
                <button
                  style="width:75px; cursor:pointer; margin-bottom:5px; height:25px; background:#333747"
                  class="uk-button-primary"
                  v-clipboard:copy="property_role._id"
                  v-clipboard:success="onCopy">
                  Copy ID
                </button><br/>
                <button
                  style="width:75px; cursor:pointer; height:27px"
                  class="uk-button-default uk-margin-small-right"
                  @click="editForm(property_role)">
                  Edit
                </button>
                <button
                  v-if="isLoading"
                  style="width:75px; cursor:pointer; height:27px"
                  class="uk-button-danger" type="button" disabled>
                  <div uk-spinner></div>
                </button>
                <button
                  v-else
                  style="width:75px; cursor:pointer; height:27px"
                  class="uk-button-danger"
                  @click="deleteForm(property_role)">
                  Delete
                </button>
              </td>
            </tr>
          </tbody>
          <empty-table v-else :colspan="6" />
        </table>
      </div>
      <pagination
        :total-data="property_role_settings.totalDocs"
        :change-limit="changeLimit"
        :change-page="changePage"
      />
    </div>

    <div id="propertyRoleFormModal" uk-modal esc-close="false" bg-close="false">
      <div class="uk-modal-dialog">
        <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('propertyRoleFormModal')"></button>
        <div class="uk-modal-header">
          <h2 class="uk-modal-title">Property-Role Setting Form</h2>
        </div>
        <div class="uk-modal-body">

          <div class="uk-margin">
            <label class="uk-form-label">Company Role <b class="uk-text-danger">*</b></label>
            <multiselect
              v-model="propertyRoleForm.role_id"
              placeholder="Type to search role"
              label="name"
              name="role"
              track-by="name"
              v-validate="'required'"
              :options="role_options"
              :searchable="true"
              :loading='isFetching'
              :internal-search="false"
              :options-limit="50"
              @search-change="setRoleValues"
              >
            </multiselect>
            <span class="uk-text-small uk-text-danger" v-show="errors.has('role')">{{ errors.first('role') }}</span>
          </div>

          <div class="uk-margin">
            <label class="uk-form-label">Property <b class="uk-text-danger">*</b></label>
            <multiselect
              v-model="propertyRoleForm.company_office_id"
              placeholder="Type to search property"
              label="name"
              name="property"
              track-by="name"
              v-validate="'required'"
              :options="office_options"
              :searchable="true"
              :loading='isFetching'
              :internal-search="false"
              :options-limit="50"
              @search-change="setPropertyValues"
              >
            </multiselect>
            <span class="uk-text-small uk-text-danger" v-show="errors.has('property')">{{ errors.first('property') }}</span>
          </div>

          <div class="uk-margin">
            <label class="uk-form-label">Fee Normal (Rp)<b class="uk-text-danger">*</b></label>
            <input
              class="uk-input"
              :class="{'uk-form-danger': errors.has('fee_normal')}"
              name="fee_normal"
              type="number"
              v-model="propertyRoleForm.fee_normal"
              v-validate="'required'"
              placeholder="Normal Fee">
            <span class="uk-text-small uk-text-danger" v-show="errors.has('fee_normal')">{{ errors.first('fee_normal') }}</span>
          </div>

          <div class="uk-margin-small uk-width-1-2">
            <label class="uk-form-label">Fee Normal Unit <b class="uk-text-danger">*</b></label>
            <multiselect
              v-model="propertyRoleForm.fee_normal_unit"
              placeholder="Fee Unit"
              name="fee_normal_unit"
              v-validate="'required'"
              :options="unit_options">
            </multiselect>
            <span class="uk-text-small uk-text-danger" v-show="errors.has('fee_normal_unit')">{{ errors.first('fee_normal_unit') }}</span>
          </div>

          <div class="uk-margin">
            <label class="uk-form-label">Fee Overtime Hourly (Rp)<b class="uk-text-danger">*</b></label>
            <input
              class="uk-input"
              :class="{'uk-form-danger': errors.has('fee_overtime_hourly')}"
              name="fee_overtime_hourly"
              type="number"
              v-model="propertyRoleForm.fee_overtime_hourly"
              v-validate="'required'"
              placeholder="Overtime Fee">
            <span class="uk-text-small uk-text-danger" v-show="errors.has('fee_overtime_hourly')">{{ errors.first('fee_overtime_hourly') }}</span>
          </div>

          <div class="uk-margin">
            <label class="uk-form-label">Normal Workhour (Hours)<b class="uk-text-danger">*</b></label>
            <input
              class="uk-input"
              :class="{'uk-form-danger': errors.has('normal_work_hour')}"
              name="normal_work_hour"
              type="number"
              v-model="propertyRoleForm.normal_work_hour"
              v-validate="'required'"
              placeholder="Normal Workhour">
            <span class="uk-text-small uk-text-danger" v-show="errors.has('normal_work_hour')">{{ errors.first('normal_work_hour') }}</span>
          </div>

          <div class="uk-margin">
            <label class="uk-form-label">Break time (Hours)<b class="uk-text-danger">*</b></label>
            <input
              class="uk-input"
              :class="{'uk-form-danger': errors.has('break_time')}"
              name="break_time"
              type="number"
              v-model="propertyRoleForm.break_time"
              v-validate="'required'"
              placeholder="Break Time">
            <span class="uk-text-small uk-text-danger" v-show="errors.has('break_time')">{{ errors.first('break_time') }}</span>
          </div>

          <div class="uk-margin">
            <label class="uk-form-label">
              <input
                class="uk-checkbox uk-margin-small-right"
                name="status"
                type="checkbox"
                v-model="propertyRoleForm.totaltime_includes_break"
              >
                Workhour includes break
            </label>
          </div>

        </div>
        <div class="uk-modal-footer uk-text-right">
          <button v-if="isLoading" class="uk-button uk-button-primary" type="button" disabled><div uk-spinner></div></button>
          <button v-else class="uk-button uk-button-primary" type="button" @click="saveForm">Save</button>
        </div>
      </div>
    </div>

    <div id="propertyRoleDeleteModal" uk-modal esc-close="false" bg-close="false">
      <div class="uk-modal-dialog">
        <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('propertyRoleDeleteModal')"></button>
        <div class="uk-modal-header">
          <h2 class="uk-modal-title">Delete Property Type?</h2>
        </div>
        <div class="uk-modal-body">
          <div class="uk-margin-small">
            <div>
              <h4>Property Name:</h4>
              <p>{{propertyRoleForm.company_office_id.name}}</p>
            </div>
            <div>
              <h4>Role Name:</h4>
              <p>{{propertyRoleForm.role_id.name}}</p>
            </div>
            <div>
              <h4>Normal Fee (Rp):</h4>
              <p>{{`${propertyRoleForm.fee_normal} / ${propertyRoleForm.fee_normal_unit}`}}</p>
            </div>
            <div>
              <h4>Normal Fee Hourly (Rp):</h4>
              <p>{{propertyRoleForm.fee_overtime_hourly}}</p>
            </div>
          </div>
        </div>
        <div class="uk-modal-footer uk-text-right">
          <button v-if="isLoading" class="uk-button uk-button-primary" type="button" disabled><div uk-spinner></div></button>
          <button v-else class="uk-button uk-button-danger" type="button" @click="markDelete">Delete</button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Pagination from '@/components/globals/Pagination';
import EmptyTable from '@/components/globals/tables/EmptyTable';
import { mapActions, mapGetters } from 'vuex';
import {
    notificationSuccess,
    notificationDanger
} from '@/utils/notification';

export default {
    data() {
        return {
            meta: {
                limit: 100,
                page: 1,
                role_id: '',
                company_office_id: '',
                is_deleted: false,
            },
            propertyRoleForm: {
                role_id: '',
                company_office_id: '',
                fee_normal: null,
                fee_normal_unit: '',
                fee_overtime_hourly: null,
                normal_work_hour: null,
                break_time: null,
                totaltime_includes_break: false,
            },
            office_options: [],
            role_options: [],
            unit_options: ['Hour', 'Day', 'Month'],
            formIsEdit: false,
            isLoading: false,
            isFetching: false,
            filterType: null,
        };
    },
    components: {
        Pagination,
        EmptyTable
    },
    computed: {
        ...mapGetters({
            property_role_settings: 'property_role_setting/property_role_settings',
            company_roles: 'company_role/company_roles',
            properties: 'property/properties',
        })
    },
    watch: {
        meta: {
            handler: function() {
                this.setMeta();
            }, deep:true
        },
        filterType: {
            handler: function() {
                this.meta = {
                    ... this.meta,
                    role_id: '',
                    company_office_id: '',
                };
            }
        }
    },
    mounted() {
        this.setMeta();
    },
    methods: {
        ...mapActions({
            getPropertyRoleSetting: 'property_role_setting/getPropertyRoleSetting',
            createPropertyRoleSetting: 'property_role_setting/createPropertyRoleSetting',
            updatePropertyRoleSetting: 'property_role_setting/updatePropertyRoleSetting',
            deletePropertyRoleSetting: 'property_role_setting/deletePropertyRoleSetting',
            getCompanyRoles: 'company_role/getCompanyRoles',
            getProperties: 'property/getProperties',
        }),
        setMeta() {
            this.getPropertyRoleSetting({
                ...this.meta,
                role_id: this.meta.role_id.value,
                company_office_id: this.meta.company_office_id.value
            });
        },
        async setPropertyValues(query) {
            this.isFetching = true;

            await this.getProperties({name: query});
            this.office_options = this.properties.docs.map(
                obj => ({name: obj.name, value: obj._id})
            );

            this.isFetching = false;
        },
        async setRoleValues(query) {
            this.isFetching = true;

            await this.getCompanyRoles({name: query});
            this.role_options = this.company_roles.docs.map(
                obj => ({name: obj.name, value: obj._id})
            );

            this.isFetching = false;
        },
        onQueryChange(query) {
            if (this.filterType === 'role_id') this.setRoleValues(query);
            else this.setPropertyValues(query);
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        async saveForm() {
            if (process.env.NODE_ENV !== 'production') {
                console.log(this.propertyRoleForm);
            }
            try {
                const validate = await this.$validator.validateAll();
                if (!validate || this.$validator.errors.any()) return;

                this.isLoading = true;

                this.propertyRoleForm.role_id = this.propertyRoleForm.role_id.value;
                this.propertyRoleForm.company_office_id = this.propertyRoleForm.company_office_id.value;

                const response = this.formIsEdit
                    ? await this.updatePropertyRoleSetting(this.propertyRoleForm)
                    : await this.createPropertyRoleSetting(this.propertyRoleForm);

                this.isLoading = false;
                if (response && response.status === 'OK') {
                    notificationSuccess('Property-role setting saved!');
                    this.hideModal('propertyRoleFormModal');
                    this.setMeta();
                } else {
                    notificationDanger('Failed to save property-role setting.');
                }
            } catch (err) {
                notificationDanger(err);
            }

        },
        async markDelete() {
            try {
                this.isLoading = true;
                const response = await this.deletePropertyRoleSetting(this.propertyRoleForm.id);
                this.isLoading = false;

                if (response && response.status === 'OK') {
                    notificationSuccess('Property-role setting deleted!');
                    this.hideModal('propertyRoleDeleteModal');
                    this.setMeta();
                } else {
                    notificationDanger('Failed to delete property-role setting.');
                }

            } catch (err) {
                notificationDanger(err);
            }
        },
        fillForm(property_role) {
            try {
                if (process.env.NODE_ENV !== 'production') {
                    console.log(property_role);
                }
                this.propertyRoleForm.id = property_role._id;
                [
                    'fee_normal', 'fee_normal_unit', 'totaltime_includes_break',
                    'fee_overtime_hourly', 'break_time', 'normal_work_hour'
                ].forEach(prop => {
                    this.propertyRoleForm[prop] = property_role[prop];
                });
                this.propertyRoleForm.company_office_id = {
                    name: property_role.company_office.name,
                    value: property_role.company_office._id
                };
                this.propertyRoleForm.role_id = {
                    name: property_role.role.name,
                    value: property_role.role._id
                };
            } catch (err) {
                notificationDanger(err);
            }
        },
        editForm(property_role) {
            this.formIsEdit = true;
            this.fillForm(property_role);
            this.showModal('propertyRoleFormModal');
        },
        deleteForm(property_role) {
            this.fillForm(property_role);
            this.showModal('propertyRoleDeleteModal');
        },
        resetForm() {
            this.formIsEdit = false;
            this.propertyRoleForm = {
                role_id: '',
                company_office_id: '',
                fee_normal: null,
                fee_normal_unit: '',
                fee_overtime_hourly: null,
                normal_work_hour: null,
                break_time: null,
                totaltime_includes_break: false,
            };
            this.$validator.reset();
        },
        async showModal(modal) {
            await window.UIkit.modal(`#${modal}`).show();
        },
        async hideModal(modal) {
            await window.UIkit.modal(`#${modal}`).hide();
            this.resetForm();
        },
        onCopy(e) {
            notificationSuccess('Copied to clipboard: ' + e.text);
        },
    }
};
</script>
